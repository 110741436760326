import React from "react";
import "./Footer.css";
import logo from "./../assets/logo.svg";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="main-footer">
      <div className="text">
        <p>
          &copy;{new Date().getFullYear()}{" "}
          {process.env.REACT_APP_FOOTER + " " + t("main.footer.content") + " "}
          <a
            href={
              "mailto:" +
              process.env.REACT_APP_SUPPORT_EMAIL +
              "?subject=Richiesta supporto smartest.fun"
            }
          >
            {process.env.REACT_APP_SUPPORT_EMAIL}
          </a>
        </p>
      </div>
      <div className="image">
        <a href="https://www.smartest.fun" rel="noreferrer" target="_blank">
          <img src={logo} alt="logo" className="logo" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
