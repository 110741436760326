import { useEffect, useRef } from "react";

// 73
function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return { r, g, b };
  }
  return null;
}

function flattenAlpha({ r, g, b }, opacity) {
  const bgCol = { r: 255, g: 255, b: 255 };

  return {
    r: opacity * r + (1 - opacity) * bgCol.r,
    g: opacity * g + (1 - opacity) * bgCol.g,
    b: opacity * b + (1 - opacity) * bgCol.b,
  };
}

function rgbToStr({ r, g, b }) {
  return `rgb(${r},${g},${b})`;
}

function myFireworks({
  bg = "yellow",
  opacity = 0.47,
  resize = () => [window.innerWidth, window.innerHeight],
}) {
  let canvas,
    ctx,
    w,
    h,
    particles = [],
    probability = 0.04,
    xPoint,
    yPoint,
    stopped = false;

  const bgWithOpacity = rgbToStr(flattenAlpha(hexToRgb(bg), opacity));

  function start() {
    canvas = document.getElementById("myFireworks");
    ctx = canvas.getContext("2d");
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas, false);
    window.requestAnimationFrame(updateWorld);
  }

  function stop() {
    window.removeEventListener("resize", resizeCanvas, false);
    stopped = true;
  }

  function resizeCanvas() {
    if (!!canvas) {
      const [width, height] = resize();
      w = canvas.width = width;
      h = canvas.height = height;
    }
  }

  function updateWorld() {
    if (!stopped) {
      update();
      paint();
      window.requestAnimationFrame(updateWorld);
    }
  }

  function update() {
    if (particles.length < 500 && Math.random() < probability) {
      createFirework();
    }
    let alive = [];
    for (let i = 0; i < particles.length; i++) {
      if (particles[i].move()) {
        alive.push(particles[i]);
      }
    }
    particles = alive;
  }

  function paint() {
    ctx.globalCompositeOperation = "source-over";
    ctx.fillStyle = bgWithOpacity;
    ctx.fillRect(0, 0, w, h);
    ctx.globalCompositeOperation = "lighter";
    for (let i = 0; i < particles.length; i++) {
      particles[i].draw(ctx);
    }
  }

  function createFirework() {
    xPoint = Math.random() * (w - 200) + 100;
    yPoint = Math.random() * (h - 200) + 100;
    let nFire = Math.random() * 50 + 100;
    let c =
      "rgb(" +
      ~~(Math.random() * 200 + 55) +
      "," +
      ~~(Math.random() * 200 + 55) +
      "," +
      ~~(Math.random() * 200 + 55) +
      ")";
    for (let i = 0; i < nFire; i++) {
      let particle = new Particle();
      particle.color = c;
      let vy = Math.sqrt(25 - particle.vx * particle.vx);
      if (Math.abs(particle.vy) > vy) {
        particle.vy = particle.vy > 0 ? vy : -vy;
      }
      particles.push(particle);
    }
  }

  function Particle() {
    this.w = this.h = Math.random() * 4 + 1;

    this.x = xPoint - this.w / 2;
    this.y = yPoint - this.h / 2;

    this.vx = (Math.random() - 0.5) * 10;
    this.vy = (Math.random() - 0.5) * 10;

    this.alpha = Math.random() * 0.5 + 0.5;
  }

  Particle.prototype = {
    gravity: 0.05,
    move: function () {
      this.x += this.vx;
      this.vy += this.gravity;
      this.y += this.vy;
      this.alpha -= 0.01;
      if (
        this.x <= -this.w ||
        this.x >= window.screen.width ||
        this.y >= window.screen.height ||
        this.alpha <= 0
      ) {
        return false;
      }
      return true;
    },
    draw: function (c) {
      c.save();
      c.beginPath();

      c.translate(this.x + this.w / 2, this.y + this.h / 2);
      c.arc(0, 0, this.w, 0, Math.PI * 2);
      c.fillStyle = this.color;
      c.globalAlpha = this.alpha;

      c.closePath();
      c.fill();
      c.restore();
    },
  };

  return { start, stop };
}

const fireworksStyle = {
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
  position: "fixed",
  zIndex: -1,
  pointerEvents: "none",
};

export default function FireworksNew({ bg, opacity = 0.47 }) {
  const ref = useRef();
  useEffect(() => {
    const fireworks = myFireworks({
      bg,
      opacity,
      resize() {
        return [ref.current.offsetWidth, ref.current.offsetHeight];
      },
    });
    fireworks.start();
    return () => fireworks.stop();
  }, [bg, opacity]);
  return (
    <div ref={ref} style={fireworksStyle}>
      <canvas id="myFireworks"></canvas>
    </div>
  );
}
