import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Button, Grid } from "@material-ui/core";
import bannerErrore from "../assets/banner_errore.svg";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";

class ErrorAlert extends Component {
  render() {
    // if already answered go to profile
    if (this.props.message === "ans_err")
      return <Redirect to={"/profile/" + this.props.token} />;

    return (
      <div
        className="page-container"
        style={{
          backgroundColor:
            this.props.campaignColor + process.env.REACT_APP_ALPHA_HEX_FACTOR,
        }}
      >
        <Navbar
          nickname={this.props.nickname}
          avatar={this.props.avatar}
          logo={this.props.logo}
          color={this.props.color}
          campaignColor={this.props.campaignColor}
          {...(this.props.profileLink
            ? { profileLink: this.props.profileLink }
            : {})}
        />
        <div className="content-wrap">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            align="center"
            justify="center"
          >
            <Grid container justify="center">
              <p
                style={{
                  textAlign: "center",
                  fontSize: 23,
                  fontWeight: "700",
                  color: this.props.campaignColor,
                }}
              >
                {this.props.message || this.props.t("error.linkNotFound.title")}
              </p>
              <Grid container justify="center" className="error-container">
                <div className="error-bar"></div>
                <img
                  className="error-img"
                  alt=" "
                  src={this.props.banner || bannerErrore}
                />
              </Grid>
              <p className="error-description">
                {this.props.description ||
                  this.props.t("error.linkNotFound.description")}
              </p>
            </Grid>
            {this.props.nickname !== "" ? (
              <Grid container justify="center">
                <Link to={"/profile/" + this.props.token}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: this.props.campaignColor,
                      marginTop: 30,
                    }}
                    disableElevation
                  >
                    {this.props.t("registration.thanks.button")}
                  </Button>
                </Link>
              </Grid>
            ) : null}
          </Grid>
        </div>
        <Footer campaignColor={this.props.campaignColor} />
      </div>
    );
  }
}

ErrorAlert.defaultProps = {
  message: "", //i18n.t("error.linkNotFound.title"),
  description: "", //i18n.t("error.linkNotFound.description"),
  campaignColor: "",
  color: "",
  nickname: "",
  avatar: "",
  logo: "",
  token: "",
};

export default withTranslation()(ErrorAlert);
