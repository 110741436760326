import React from "react";
import { makeStyles } from "@bit/mui-org.material-ui.styles";
import Table from "@bit/mui-org.material-ui.table";
import TableBody from "@bit/mui-org.material-ui.table-body";
import TableCell from "@bit/mui-org.material-ui.table-cell";
import TableHead from "@bit/mui-org.material-ui.table-head";
import TableRow from "@bit/mui-org.material-ui.table-row";
import Paper from "@bit/mui-org.material-ui.paper";
import "./Leaderboard.css";
import { useTranslation } from "react-i18next";
import pos_1 from "../assets/podium/pos_1.png";
import pos_2 from "../assets/podium/pos_2.png";
import pos_3 from "../assets/podium/pos_3.png";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    //minWidth: 650,
  },
  longCell: {
    //minWidth: '12.7em',
  },
  shortCell: {
    maxWidth: "10px",
  },
}));

function userExists(arr, userid) {
  return arr.some(function (el) {
    return el.id === userid;
  });
}

export const medals = [pos_1, pos_2, pos_3];

export default function Leaderboard(props) {
  const classes = useStyles();

  const { t } = useTranslation();

  function createData(
    rank,
    avatar,
    color,
    nickname,
    progress,
    precision,
    reactionTime,
    points,
    isCurrentUser
  ) {
    return {
      rank,
      avatar,
      color,
      nickname,
      progress,
      precision,
      reactionTime,
      points,
      isCurrentUser,
    };
  }

  function pushToLeaderBoard(users, isCurrentUser = false) {
    users.forEach(function (user) {
      let progress =
        user.num_of_completed_links + " / " + user.total_num_of_links;
      rows.push(
        createData(
          user.rank,
          user.avatar,
          user.color,
          user.nickname,
          progress,
          user.mean_precision,
          user.mean_reaction_time,
          user.total_score,
          isCurrentUser
        )
      );
    });
  }

  let rows = [];

  //1. add top 5
  pushToLeaderBoard(props.top5);

  //2. add user view only if he isn't in top 5
  if (!userExists(props.top5, props.user.id)) {
    //2. add empty row (separator)
    rows.push(createData("", "", "", "", "", "", ""));

    //3. add prev2
    pushToLeaderBoard(props.prev2);

    //4. add current user
    pushToLeaderBoard([props.user], true);

    //5. add next2
    pushToLeaderBoard(props.next2);
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell className={(classes.shortCell, "table-header")}>
              #
            </TableCell>
            <TableCell
              className={(classes.longCell, "table-header")}
              align="left"
            >
              {t("leaderboard.table.nickname")}
            </TableCell>
            <TableCell className={"table-header"} align="center">
              {t("leaderboard.table.points")}
            </TableCell>
            <TableCell className={"table-header hide-mobile"} align="center">
              {t("leaderboard.table.progress")}
            </TableCell>
            <TableCell className={"table-header hide-mobile"} align="center">
              {t("leaderboard.table.precision")}
            </TableCell>
            <TableCell className={"table-header hide-mobile"} align="center">
              {t("leaderboard.table.reactionTime")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.rank}
              style={{
                position: "relative",
                backgroundColor:
                  props.user.nickname == row.nickname
                    ? props.user.color +
                      process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER
                    : "",
              }}
            >
              {!!Object.entries(row).find(([k, v]) => !!v) ? (
                <>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.shortCell}
                  >
                    {medals[parseInt(row.rank) - 1] ? (
                      <img
                        src={medals[parseInt(row.rank) - 1]}
                        alt={"pos " + row.rank}
                      />
                    ) : (
                      <span style={{ paddingLeft: "0.55rem" }}>{row.rank}</span>
                    )}
                  </TableCell>
                  <TableCell className={classes.longCell} align="center">
                    {row.avatar !== "" ? (
                      <img
                        src={
                          require(`../../public/avatars/${row.avatar}.png`)
                            .default
                        }
                        style={{ background: row.color }}
                        alt=""
                        className="avatar"
                      />
                    ) : (
                      ""
                    )}
                    <span className="nickname">{row.nickname}</span>
                  </TableCell>
                  <TableCell align="center">{row.points}</TableCell>
                  <TableCell className="hide-mobile" align="center">
                    {row.progress}
                  </TableCell>
                  <TableCell className="hide-mobile" align="center">
                    {row.precision}%
                  </TableCell>
                  <TableCell className="hide-mobile" align="center">
                    {row.reactionTime}"
                  </TableCell>
                </>
              ) : (
                <>
                  <div
                    style={{
                      height: "2.7rem",
                      border: 0,
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      background: "white",
                      top: "0px",
                      left: "0px",
                      right: "0px",
                      bottom: "0px",
                      borderBottom: "1px solid #ddd",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#ddd",
                    }}
                  >
                    <span>{t("leaderboard.table.yourPosition")}</span>
                  </div>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
/*no% inverti precis con tempo*/
