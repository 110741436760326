import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = props=>makeStyles((theme) => ({
        popover: {
            pointerEvents: 'none',
            transform: 'translateY(10px)',
        },
        paper: {
            padding: theme.spacing(2),
            border: '2px solid',
            borderColor: props.color,
            maxWidth: "250px"
        }
    }));


export default function usePopover(color) {
  const classes = useStyles({color:color||"black"})();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onMouseOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
   setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const popover = (
      anchorEl?<Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}

        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={onMouseLeave}
        disableRestoreFocus
      >
        <div style={{fontSize: '1.1rem', fontWeight: 'bold'}}>{anchorEl ? anchorEl.getAttribute('data-title') : null}</div>
      </Popover>:null
  );
  return [popover,{onMouseLeave,onMouseOver}]
}
