import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FullLeaderboard from "./FullLeaderboard";
import Loading from "./Loading";
import "./Leaderboard.css";
import { useTranslation } from "react-i18next";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const rowsPerPage = 15;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  fullWidth: true,
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function FullLeaderboardModal(props) {
  const open = props.open;

  const leaderboard = props.fullData;

  const [page, setPage] = useState(0);

  const { t } = useTranslation();

  const handleClickOpen = () => {
    props.setOpen(true);
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  if (!leaderboard.length) return <Loading />;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: props.campaignColor, marginTop: "3em" }}
        disableElevation
        onClick={handleClickOpen}
      >
        {t("leaderboard.button.showFullLeaderbord")}
      </Button>

      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ padding: "8px 15px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{t("leaderboard.modal.fullLeaderboard")}</span>
            {leaderboard.length <= rowsPerPage ? (
              // spacer
              <div style={{ height: "3rem" }} />
            ) : (
              <>
                <IconButton
                  onClick={() => setPage(page - 1)}
                  disabled={page === 0}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <input
                  style={{
                    width: "40px",
                    fontSize: "18px",
                    textAlign: "center",
                    marginRight: "5px",
                    borderRadius: "4px",
                    border: "2px solid rgba(212,212,212)",
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    if (Number.isInteger(e.target.value - 1))
                      setPage(e.target.value - 1);
                  }}
                  type="text"
                  value={page + 1}
                />{" "}
                /{" "}
                <span style={{ paddingLeft: "5px" }}>
                  {Math.ceil((leaderboard.length - 1) / rowsPerPage)}
                </span>
                <IconButton
                  onClick={() => setPage(page + 1)}
                  disabled={
                    page >= Math.floor((leaderboard.length - 1) / rowsPerPage)
                    //pag 0 14
                    // pag 1 15 30
                    // pag 2 31
                  }
                >
                  <NavigateNextIcon />
                </IconButton>
              </>
            )}
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <FullLeaderboard
            leaderboard={leaderboard}
            user={props.user}
            page={page}
            rowsPerPage={rowsPerPage}
            fullData={props.fullData}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
