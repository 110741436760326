import React from "react";
import axios from "axios";
import * as Survey from "survey-react";
import Navbar from "./Navbar";
import "./Test.css";
import "survey-react/survey.css";
import Footer from "./Footer";
import Loading from "./Loading";
import ErrorAlert from "./ErrorAlert";
import bannerBentornato from "../assets/banner_bentornato.svg";
import { withRouter } from "react-router";
import TestResultModal from "./TestResultModal";
import Countdown from "./Countdown";
import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import RenderWelcome, { DifferentialRendering } from "./RenderWelcome";
import RenderEnded from "./RenderEnded";

class Test extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      json: "",
      token: "",
      nickname: "",
      avatar: "",
      logo: "",
      color: "",
      campaignColor: "",
      loading: true,
      errorMessage: "",
      errorDescription: "",
      points: null,
      correctAnswerCount: null,
      precision: null,
      reactionTime: null,
      bonus_timer: null,
      time_spent: null,
      constrainedMode: false,
      constrainedVisible: false,
      surveysTodo: null,
      nextSurvey: null,
      isSent: false,
      rankingText: null,
      rankingPriority: null,
      welcomeStart: !localStorage.getItem("welcomeStart"),
    };

    this.onCompleteComponent = this.onCompleteComponent.bind(this);
  }

  onCompleteComponent = () => {
    this.setState({
      isComplete: true,
      waiting: true,
    });
  };

  axiosCallOnStart(read_only = false) {
    // get token
    const token = this.props.match.params.token;
    axios
      .get(
        process.env.REACT_APP_SURVEYS_ENDPOINT +
          token +
          (read_only ? "___read_only" : "")
      )
      .then((res) => {
        const json = res.data;

        let survey = new Survey.Model(json);

        // Update state to trigger a re-render.
        // Clear any errors, and turn off the loading indiciator.
        this.setState({
          json,
          token: res.data.token,
          nickname: res.data.nickname,
          avatar: res.data.avatar,
          logo: res.data.customer_logo,
          color: res.data.color,
          campaignColor: res.data.campaign_color,
          bonus_timer: res.data.bonus_timer * survey.getQuizQuestionCount(),
          time_spent: res.data.time_spent,
          constrainedMode: res.data.constrained_mode,
          constrainedVisible:
            res.data.constrained_mode &&
            res.data.surveys_todo >= 1 &&
            this.props.match.params.constrained !== "1",
          surveysTodo: res.data.surveys_todo,
          nextSurvey: res.data.next_survey,
          loading: false,
          errorMessage: "",
          is_ended: res.data.is_ended,
        });
      })
      .catch((err) => {
        // Something went wrong. Save the error in state and re-render.

        this.setState({
          token: token,
        });

        let errorMessage = this.props.t("error.general.title");
        let errorDescription = this.props.t("error.general.description");

        if (err.response !== undefined) {
          errorMessage = err.response.data.title;
          errorDescription = err.response.data.description;
        }

        this.setState({
          loading: false,
          errorMessage: errorMessage,
          errorDescription: errorDescription,
        });
      });
  }

  componentDidMount() {
    this.axiosCallOnStart(!!this.state.welcomeStart);
  }

  renderLoading() {
    return <Loading />;
  }

  renderError() {
    return (
      <ErrorAlert
        message={this.state.errorMessage}
        description={this.state.errorDescription}
        campaignColor={this.state.campaignColor}
        color={this.state.color}
        nickname={this.state.nickname}
        avatar={this.state.avatar}
        logo={this.state.logo}
        token={this.state.token}
      />
    );
  }

  renderSurvey() {
    if (this.state.errorMessage !== "") {
      return this.renderError();
    }

    var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
    defaultThemeColors["$main-color"] = this.state.campaignColor;
    defaultThemeColors["$main-hover-color"] = this.state.campaignColor + "66";

    Survey.StylesManager.applyTheme();

    let json = this.state.json;

    let survey = new Survey.Model(json);

    window.survey = survey;

    let self = this;

    survey.onComplete.add(function (sender) {
      let result = sender.data,
        correctAnswerCount = sender.getCorrectedAnswerCount(),
        numOfQuestions = sender.getQuizQuestionCount(),
        precision = ((correctAnswerCount / numOfQuestions) * 100).toFixed(0);

      self.setState({
        correctAnswerCount: correctAnswerCount,
        numOfQuestions: numOfQuestions,
        precision: isNaN(precision) ? "N/D" : precision,
      });

      //pushing values in result array
      result["correct_answers"] = correctAnswerCount;
      result["no_of_questions"] = numOfQuestions;

      let qea = [];
      sender.getAllQuestions().forEach((q) => {
        if (q.correctAnswer !== undefined) {
          qea.push({ q: q.name, title: q.title, a: q.correctAnswerCount });
        }
      });

      localStorage.setItem("resdata", JSON.stringify(result));

      axios
        .put(process.env.REACT_APP_SURVEYS_ENDPOINT + self.state.token, {
          answer: JSON.stringify(result),
          token: self.state.token,
          precision: isNaN(precision) ? 0 : precision,
          num_of_questions: numOfQuestions,
          correct_answer: correctAnswerCount,
          qea: qea,
        })
        .then(function (response) {
          self.setState({
            waiting: false,
            isSent: true,
            points: response.data.result.points_this_survey,
            reactionTime: response.data.result.reaction_time,
            rankingText: response.data.ranking_text,
            rankingPriority: response.data.ranking_priority,
            wasLastSurvey: response.data.was_last_survey ?? false,
          });
          console.log("was last", response.data.was_last_survey);
        })
        .catch(function (error) {
          self.setState({ errorMessage: error.response.data.message });
        });
    });

    let surveyRender =
      this.state.waiting || this.state.isSent ? null : (
        <Survey.Survey
          model={survey}
          showCompletedPage={false}
          onComplete={this.onCompleteComponent}
        />
      );

    let onLoading = this.state.waiting ? <Loading /> : null;

    let constrainedRender =
      this.state.constrainedMode &&
      this.state.surveysTodo >= 1 &&
      this.props.match.params.constrained !== "1" &&
      this.state.constrainedVisible ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          align="center"
          justify="center"
        >
          <Grid container justify="center">
            <Typography variant="h4" component="h4" style={{ width: "100%" }}>
              {this.props.t("test.header.welcomeBack")}
            </Typography>

            <Typography
              variant="h5"
              component="h5"
              style={{
                textAlign: "center",
                fontSize: 20,
                color: this.state.campaignColor,
                marginTop: "1rem",
              }}
            >
              {this.props.t("test.header.recover")} {this.state.surveysTodo}{" "}
              {this.state.surveysTodo === 1
                ? this.props.t("test.header.question")
                : this.props.t("test.header.questions")}
            </Typography>
            <Grid container justify="center" className="bentornato-container">
              <div
                className="bentornato-bar"
                style={{
                  backgroundColor:
                    this.state.campaignColor +
                    process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER,
                }}
              />
              <img alt="" className="bentornato-img" src={bannerBentornato} />
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: this.state.campaignColor,
                marginTop: "3em",
              }}
              disableElevation
              onClick={() => this.setState({ constrainedVisible: false })}
            >
              {this.props.t("test.header.answerNow")}
            </Button>
          </Grid>
        </Grid>
      ) : null;

    return (
      <div
        className="page-container"
        style={{
          backgroundColor:
            this.state.campaignColor + process.env.REACT_APP_ALPHA_HEX_FACTOR,
        }}
      >
        <Navbar
          nickname={this.state.nickname}
          avatar={this.state.avatar}
          logo={this.state.logo}
          color={this.state.color}
          campaignColor={this.state.campaignColor}
        />
        <div className="content-wrap">
          {constrainedRender}

          {this.state.bonus_timer !== null &&
          !this.state.constrainedVisible &&
          !this.state.isSent &&
          !this.state.waiting ? (
            <Countdown
              timeTotal={this.state.bonus_timer}
              timeSpent={this.state.time_spent}
            />
          ) : null}

          {!this.state.constrainedVisible ? surveyRender : null}

          {this.state.waiting ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                background:
                  this.state.campaignColor +
                  process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER,
                alignItems: "center",
              }}
            >
              <Loading />
            </div>
          ) : null}

          <TestResultModal
            open={this.state.isSent}
            points={this.state.points}
            precision={this.state.precision}
            reactionTime={this.state.reactionTime}
            token={this.state.token}
            urlToken={this.props.match.params.token}
            campaignColor={this.state.campaignColor}
            nextSurvey={this.state.nextSurvey}
            rankingText={this.state.rankingText}
            rankingPriority={this.state.rankingPriority}
            wasLastSurvey={this.state.wasLastSurvey}
          />
        </div>
        <Footer campaignColor={this.state.campaignColor} />
      </div>
    );
  }

  renderWelcome() {
    return (
      <RenderWelcome
        {...this.state}
        that={this}
        labels={[
          "test.header.welcome",
          "test.header.welcomeBelow",
          "test.header.buttonWelcome",
        ]}
      />
    );
  }

  renderEnded() {
    return <RenderEnded {...this.state} that={this} />;
  }

  render() {
    return DifferentialRendering(this);
  }
}

export default withTranslation()(withRouter(Test));
