import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Navbar from "./Navbar";
import bannerBenvenuto from "../assets/banner_benvenuto.svg";

export default function RenderWelcome(props) {
  const campaignColor = props.campaignColor;
  const { t } = useTranslation();
  return (
    <div
      className="page-container"
      style={{
        backgroundColor: campaignColor + process.env.REACT_APP_ALPHA_HEX_FACTOR,
      }}
    >
      <Navbar
        nickname={props.nickname}
        avatar={props.avatar}
        logo={props.logo}
        color={props.color}
        campaignColor={props.campaignColor}
      />
      <div className="content-wrap">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          align="center"
          justify="center"
        >
          <Grid container justify="center">
            <Typography variant="h4" component="h4" style={{ width: "100%" }}>
              {props.labels[0] ? t(props.labels[0]) : null}
            </Typography>

            <Typography
              variant="h5"
              component="h5"
              style={{
                textAlign: "center",
                fontSize: 20,
                color: campaignColor,
                marginTop: "1rem",
              }}
            >
              {props.labels[1] ? t(props.labels[1]) : null}
            </Typography>
            <Grid container justify="center" className="bentornato-container">
              <div
                className="bentornato-bar"
                style={{
                  backgroundColor:
                    campaignColor +
                    process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER,
                }}
              />
              <img alt="" className="bentornato-img" src={bannerBenvenuto} />
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: campaignColor,
                marginTop: "3em",
              }}
              disableElevation
              onClick={() => {
                localStorage.setItem("welcomeStart", "ok");
                props.that.setState({ loading: true, welcomeStart: false });
                props.that.axiosCallOnStart();
              }}
            >
              {props.labels[2] ? t(props.labels[2]) : null}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Footer campaignColor={props.campaignColor} />
    </div>
  );
}

export function DifferentialRendering(that) {
  return (
    <div>
      {that.state.loading
        ? that.renderLoading()
        : that.state.is_ended
        ? that.renderEnded()
        : that.state.welcomeStart
        ? that.renderWelcome()
        : that.renderSurvey()}
    </div>
  );
}
