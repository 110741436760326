import React from "react";
import "./Navbar.css";
import logo from "../assets/logo.png";
import logoSmall from "../assets/logo_small.png";
import defaultAvatar from "../assets/default_avatar.svg";
import usePopover from "./Popover";
import { useTranslation } from "react-i18next";
import useIsMobile from "./useIsMobile";
import { ChristmasLights, isChristmasTime } from "./Christmas";

function Navbar(props) {
  const [popover, handlers] = usePopover(props.campaignColor);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  // Il parametro __WELCOME_TEXT__ proviene dal backend
  // E' un messaggio per dire: "Sostituisci col testo di welcome"
  const userNameOrWelcome = (
    <span className="userName">
      {props.nickname === "__WELCOME_TEXT__"
        ? t("main.header.welcome") + " !"
        : props.nickname}
    </span>
  );

  return (
    <>
      <nav>
        {props.logo !== "" ? (
          <img
            alt=""
            src={
              !isMobile
                ? require(`../../public/logos/${props.logo}.png`).default
                : require(`../../public/logos/${props.logo}_small.png`).default
            }
            className="logo-left"
          />
        ) : (
          <img
            alt=""
            src={!isMobile ? logo : logoSmall}
            className="logo-left"
          />
        )}
        <div className="user-info">
          {props.profileLink !== undefined ? (
            <a
              href={props.profileLink}
              {...handlers}
              data-title={t("main.profile.show")}
            >
              {userNameOrWelcome}
            </a>
          ) : (
            userNameOrWelcome
          )}

          {props.profileLink !== undefined ? (
            <a
              href={props.profileLink}
              {...handlers}
              data-title={t("main.profile.show")}
            >
              <img
                alt=""
                src={
                  !!props.avatar
                    ? require(`../../public/avatars/${props.avatar}.png`)
                        .default
                    : defaultAvatar
                }
                style={{ background: props.color }}
                className="big-avatar"
              />
            </a>
          ) : (
            <img
              alt=""
              src={
                !!props.avatar
                  ? require(`../../public/avatars/${props.avatar}.png`).default
                  : defaultAvatar
              }
              style={{ background: props.color }}
              className="big-avatar"
            />
          )}
        </div>
        {isChristmasTime() ? <ChristmasLights /> : null}
      </nav>
      {popover}
    </>
  );
}

export default Navbar;
