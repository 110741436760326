import React from "react";
import Grid from "@material-ui/core/Grid";
import "./UserProfile.css";
import leaderboard from "../assets/leaderboard.svg";
import statistics from "../assets/statistics.svg";
import points_icon from "../assets/points_icon.svg";
import precision from "../assets/precision.svg";
import reaction_time from "../assets/reaction_time.svg";
import progress from "../assets/progress.svg";
import rules from "../assets/rules.svg";
import Leaderboard from "./Leaderboard";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FullLeaderboardModal from "./FullLeaderboardModal";
import usePopover from "./Popover";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import useIsMobile, { useIsTablet } from "./useIsMobile";

export default function UserProfileRender(props) {
  const [popover, handlers] = usePopover(props.campaignColor);

  const { t } = useTranslation();

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  let boxSize = 3;
  if (isTablet) {
    boxSize = 6;
  } else if (isMobile) {
    boxSize = 12;
  }

  return (
    <div
      className="page-container"
      style={{
        backgroundColor:
          props.campaignColor + process.env.REACT_APP_ALPHA_HEX_FACTOR,
      }}
    >
      <Navbar
        nickname={props.nickname}
        avatar={props.avatar}
        logo={props.logo}
        color={props.color}
        campaignColor={props.campaignColor}
        profileLink={"/register/" + props.registrationToken}
      />

      <div className="content-wrap" style={{ backgroundColor: "white" }}>
        <div className="main-box">
          <Grid container spacing={1}>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <h1
                  className="MuiTableCell-alignCenter"
                  style={{ color: props.campaignColor }}
                >
                  {props.campaignName}
                </h1>
                
                <p dangerouslySetInnerHTML={{ __html: props.campaignDescription }}></p>

                <a
                  href={props.rulesUrl}
                  target="_blank"
                  {...handlers}
                  data-title={t("main.profile.rulesTitle")}
                  className="link-rules"
                  rel="noreferrer"
                >
                  <img
                    src={rules}
                    className="icon-rules"
                    alt={t("main.profile.rulesTitle")}
                  />
                </a>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                // NEVER PUT DIRECTION COLUMN WITH XS TOGETHER
                // https://material-ui.com/components/grid/#direction-column-column-reverse
                direction={"row"}
                spacing={!isMobile ? 0 : 0.5}
              >
                <Grid item xs={12} spacing={2}>
                  <div className="section-header">
                    <img
                      src={statistics}
                      className="icon"
                      alt={t("main.profile.statistics")}
                    />
                    <h2 style={{ color: props.campaignColor }}>
                      {t("main.profile.statistics")}
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={boxSize} spacing={2}>
                  <div
                    className="rounded-border"
                    style={{
                      borderColor: props.campaignColor,
                      backgroundColor:
                        props.campaignColor +
                        process.env.REACT_APP_ALPHA_HEX_FACTOR_MIN,
                    }}
                    data-title={t("survey.box.progress")}
                    {...handlers}
                  >
                    <div className="text-center">
                      <img src={progress} alt="" />
                    </div>
                    <div className="box-data">
                      {props.numOfCompletedLinks !== null ? (
                        <CountUp end={props.numOfCompletedLinks} />
                      ) : (
                        "ND"
                      )}{" "}
                      /{" "}
                      {props.totalNumOfLinks !== null
                        ? props.totalNumOfLinks
                        : "ND"}
                    </div>
                    <p className="box-label">
                      {t("leaderboard.table.progress")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={boxSize} spacing={2}>
                  <div
                    className="rounded-border"
                    style={{
                      borderColor: props.campaignColor,
                      backgroundColor:
                        props.campaignColor +
                        process.env.REACT_APP_ALPHA_HEX_FACTOR_MIN,
                    }}
                    data-title={t("survey.box.points")}
                    {...handlers}
                  >
                    <div className="text-center">
                      <img src={points_icon} className="small-icon" alt="" />
                    </div>
                    <div className="box-data">
                      {props.points !== null ? (
                        <CountUp end={props.points} />
                      ) : (
                        "ND"
                      )}
                    </div>
                    <p className="box-label">{t("leaderboard.table.points")}</p>
                  </div>
                </Grid>
                <Grid item xs={boxSize} spacing={2}>
                  <div
                    className="rounded-border"
                    style={{
                      borderColor: props.campaignColor,
                      backgroundColor:
                        props.campaignColor +
                        process.env.REACT_APP_ALPHA_HEX_FACTOR_MIN,
                    }}
                    data-title={t("survey.box.precision")}
                    {...handlers}
                  >
                    <div className="text-center">
                      <img src={precision} alt="" />
                    </div>
                    <div className="box-data">
                      {props.precision !== null ? (
                        <CountUp end={props.precision} suffix="%" />
                      ) : (
                        100
                      )}
                    </div>
                    <p className="box-label">
                      {t("leaderboard.table.precision")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={boxSize} spacing={2}>
                  <div
                    className="rounded-border"
                    style={{
                      borderColor: props.campaignColor,
                      backgroundColor:
                        props.campaignColor +
                        process.env.REACT_APP_ALPHA_HEX_FACTOR_MIN,
                    }}
                    data-title={t("survey.box.reactionTime")}
                    {...handlers}
                  >
                    <div className="text-center">
                      <img src={reaction_time} className="small-icon" alt="" />
                    </div>
                    <div className="box-data">
                      {props.reactionTime !== null ? (
                        <CountUp end={props.reactionTime} suffix='"' />
                      ) : (
                        "ND"
                      )}
                    </div>
                    <p className="box-label">
                      {t("leaderboard.table.reactionTime")}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            {"top5" in props.json ? (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <div className="section-header">
                    <img src={leaderboard} className="icon" alt="Leaderboard" />
                    <h2 style={{ color: props.campaignColor }}>
                      {t("main.profile.leaderboard")}
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Leaderboard
                    user={props.json.user}
                    top5={props.json.top5}
                    prev2={props.json.prev2}
                    next2={props.json.next2}
                  />
                </Grid>
                <FullLeaderboardModal
                  user={props.json.user}
                  open={props.modalOpen}
                  setOpen={props.setModalOpen}
                  campaign={props.campaignId}
                  campaignColor={props.campaignColor}
                  fullData={props.json.fullData}
                />
              </Grid>
            ) : null}
          </Grid>
          {popover}
        </div>
      </div>
      <Footer campaignColor={props.campaignColor} />
    </div>
  );
}
