import "./Christmas.css";
import Snowfall from "react-snowfall";

export function isChristmasTime() {
  const year = new Date().getFullYear();
  const now = new Date().getTime();
  const start = new Date(year + "/12/08").getTime();
  const end = new Date(year + "/01/06").getTime();
  return start <= now || now <= end;
}

export function ChristmasLights() {
  return (
    <ul className="lightrope">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  );
}

export function ChristmasSnow() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
        zIndex: 1,
      }}
      className="christmas_snow_container"
    >
      <Snowfall />
    </div>
  );
}
