import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Test from "./Components/Test";
import ErrorAlert from "./Components/ErrorAlert";
import React from "react";
import UserProfile from "./Components/UserProfile";
import Registration from "./Components/Registration";
import { Helmet } from "react-helmet";
import { ChristmasSnow, isChristmasTime } from "./Components/Christmas";

import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Helmet>
          <title>Smartest.fun | {t("main.header.welcome")}</title>
        </Helmet>

        <BrowserRouter>
          <Switch>
            <Route path="/tests/:token/:constrained?" component={Test} />
            <Route path="/register/:token" exact component={Registration} />
            <Route path="/profile/:token" exact component={UserProfile} />
            <Route path="/" render={() => <ErrorAlert />} />
          </Switch>
        </BrowserRouter>
      </div>
      {isChristmasTime() ? <ChristmasSnow /> : null}
    </>
  );
}

export default App;
