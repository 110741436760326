import React from "react";
import axios from "axios";
import * as Survey from "survey-react";
import Navbar from "./Navbar";
import "./Registration.css";
import "survey-react/survey.css";
import Footer from "./Footer";
import Loading from "./Loading";
import ErrorAlert from "./ErrorAlert";
import SurveyResult from "./SurveyResult";
import { withRouter } from "react-router";
import UpdateProfileResult from "./UpdateProfileResult";
import { withTranslation } from "react-i18next";
import RenderWelcome, { DifferentialRendering } from "./RenderWelcome";
import RenderEnded from "./RenderEnded";
import FireworksNew from "./FireworksNew";

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      json: "",
      token: "",
      nickname: "",
      avatar: "",
      logo: "",
      registrationToken: "",
      color: "",
      campaignColor: "",
      loading: true,
      errorMessage: "",
      errorDescription: "",
      points: null,
      correctAnswerCount: null,
      numOfQuestions: null,
      precision: null,
      reactionTime: null,
      welcomeStart: !localStorage.getItem("welcomeStart"),
    };

    this.onCompleteComponent = this.onCompleteComponent.bind(this);
  }

  onCompleteComponent = () => {
    this.setState({
      isComplete: true,
    });
  };

  axiosCallOnStart(read_only = false) {
    //get token
    const token = this.props.match.params.token;
    this.setState({
      token: token,
    });

    //get json data
    axios
      .get(
        process.env.REACT_APP_REGISTRATION_ENDPOINT +
          token +
          (read_only ? "___read_only" : "")
      )
      .then((res) => {
        const json = res.data.result;

        // Update state to trigger a re-render.
        // Clear any errors, and turn off the loading indiciator.
        this.setState({
          json,
          nickname: res.data.result.nickname,
          avatar: res.data.result.avatar,
          logo: res.data.result.customer_logo,
          color: res.data.result.color,
          registrationToken: token,
          campaignColor: res.data.result.campaign_color,
          loading: false,
          errorMessage: "",
          nextSurvey: res.data.next_survey ?? null,
        });
      })
      .catch((err) => {
        // Something went wrong. Save the error in state and re-render.

        let errorMessage = this.props.t("error.general.title");
        let errorDescription = this.props.t("error.general.description");

        if (err.response !== undefined) {
          errorMessage = err.response.data.title;
          errorDescription = err.response.data.description;
        }

        this.setState({
          loading: false,
          errorMessage: errorMessage,
          errorDescription: errorDescription,
        });
      });
  }

  componentDidMount() {
    this.axiosCallOnStart(!!this.state.welcomeStart);
  }

  renderLoading() {
    return <Loading />;
  }

  renderError() {
    return (
      <ErrorAlert
        message={this.state.errorMessage}
        description={this.state.errorDescription}
      />
    );
  }

  renderSurvey() {
    if (this.state.errorMessage !== "") {
      return this.renderError();
    }

    var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
    defaultThemeColors["$main-color"] = this.state.campaignColor;
    defaultThemeColors["$main-hover-color"] = this.state.campaignColor + "66";

    Survey.StylesManager.applyTheme();

    let json = this.state.json;

    let survey = new Survey.Model(json);
    window.survey = survey;

    let self = this;

    survey.onComplete.add(function (sender) {
      let result = sender.data;

      localStorage.setItem("resdata", JSON.stringify(result));
      self.setState({ loading: true });
      axios
        .put(process.env.REACT_APP_REGISTRATION_ENDPOINT + self.state.token, {
          answer: JSON.stringify(result),
          token: self.state.token,
        })
        .then(function (response) {
          self.setState({
            loading: false,
            nextSurvey: response.data.next_survey ?? null,
            points: response.data.result.points_this_survey,
            reactionTime: response.data.result.reaction_time,
          });
        })
        .catch(function (error) {
          self.setState({
            loading: false,
            errorMessage: error.response.data.message,
          });
        });
    });

    let surveyRender = !this.state.isComplete ? (
      <Survey.Survey
        model={survey}
        showCompletedPage={false}
        onComplete={this.onCompleteComponent}
      />
    ) : null;

    let onSurveyCompletion = null;

    if (this.state.isComplete) {
      if (this.nickname === "")
        onSurveyCompletion = (
          <>
            <FireworksNew
              bg={
                this.state.campaignColor /* 
            al momento la trasparency è hardcodata nel file di fireworksNew, se bisogna cambiarla va hardcodata in modo diverso
            + process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER */
              }
              opacity={0.1}
            />
            <SurveyResult
              points={this.state.points}
              precision={"-"}
              reactionTime={this.state.reactionTime}
              token={this.state.token}
              campaignColor={this.state.campaignColor}
              nextSurvey={this.state.nextSurvey}
            />
            );
          </>
        );
      else
        onSurveyCompletion = (
          <>
            <FireworksNew
              bg={
                this.state.campaignColor /* 
        al momento la trasparency è hardcodata nel file di fireworksNew, se bisogna cambiarla va hardcodata in modo diverso
        + process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER */
              }
              opacity={0.1}
            />
            <UpdateProfileResult
              token={this.state.token}
              campaignColor={this.state.campaignColor}
              nextSurvey={this.state.nextSurvey}
            />
          </>
        );
    }

    let profileLink = "";
    if (this.state.avatar != "") {
      profileLink = "/profile/" + this.state.registrationToken;
    }

    return (
      <div
        className="page-container"
        style={{
          backgroundColor:
            this.state.campaignColor + process.env.REACT_APP_ALPHA_HEX_FACTOR,
        }}
      >
        <Navbar
          nickname={this.state.nickname}
          avatar={this.state.avatar}
          logo={this.state.logo}
          color={this.state.color}
          campaignColor={this.state.campaignColor}
          profileLink={profileLink}
        />
        <div className="content-wrap">
          {surveyRender}
          {onSurveyCompletion}
        </div>
        <Footer campaignColor={this.state.campaignColor} />
      </div>
    );
  }

  renderWelcome() {
    return (
      <RenderWelcome
        {...this.state}
        that={this}
        labels={[
          "test.header.welcome",
          "test.header.welcomeRegisterBelow",
          "test.header.buttonRegisterWelcome",
        ]}
      />
    );
  }

  renderEnded() {
    return <RenderEnded {...this.state} that={this} />;
  }

  render() {
    console.log("registration");
    return DifferentialRendering(this);
  }
}

export default withTranslation()(withRouter(Registration));
