import React from "react";
import { Button, Grid } from "@material-ui/core";
import points_icon from "../assets/points_icon.svg";
import precision from "../assets/precision.svg";
import reaction_time from "../assets/reaction_time.svg";
import "./SurveyResult.css";
import { Link } from "react-router-dom";
import usePopover from "./Popover";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import useIsMobile from "./useIsMobile";

function SurveyResult(props) {
  const [popover, handlers] = usePopover(props.campaignColor);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const mobileStyles = isMobile
    ? {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }
    : {};
  const pMobileStyles = isMobile
    ? { margin: "auto 5px auto auto", textAlign: "right" }
    : {};
  const iconMobileStyles = isMobile ? { width: "40px", height: "40px" } : {};
  return (
    <Grid
      container
      // NEVER PUT DIRECTION COLUMN WITH XS TOGETHER
      // https://material-ui.com/components/grid/#direction-column-column-reverse
      direction={"row"}
      spacing={!isMobile ? 0.5 : 2}
      className="container-block"
    >
      <Grid item xs={!isMobile ? true : 12}>
        <div
          data-title={t("survey.box.points")}
          {...handlers}
          className={!isMobile ? "rounded-border" : "rounded-border-mobile"}
          style={{
            ...mobileStyles,
            borderColor: props.campaignColor,
            backgroundColor:
              props.campaignColor + process.env.REACT_APP_ALPHA_HEX_FACTOR_MIN,
          }}
        >
          <div className="text-center">
            <img
              src={points_icon}
              className={!isMobile ? "small-icon" : "small-icon-mobile"}
              alt=""
              style={{ ...iconMobileStyles }}
            />
          </div>
          <div className={!isMobile ? "box-data" : "box-data-mobile"}>
            {props.points !== null ? <CountUp end={props.points} /> : "ND"}
          </div>
          <p
            className={!isMobile ? "box-label" : "box-label-mobile"}
            style={{ ...pMobileStyles }}
          >
            {t("leaderboard.table.points")}
          </p>
        </div>
      </Grid>
      <Grid item xs={!isMobile ? true : 12}>
        <div
          data-title={t("survey.box.points")}
          {...handlers}
          className={!isMobile ? "rounded-border" : "rounded-border-mobile"}
          style={{
            ...mobileStyles,
            borderColor: props.campaignColor,
            backgroundColor:
              props.campaignColor + process.env.REACT_APP_ALPHA_HEX_FACTOR_MIN,
          }}
        >
          <div className="text-center">
            <img
              src={precision}
              className={!isMobile ? "small-icon" : "small-icon-mobile"}
              alt=""
              style={{ ...iconMobileStyles }}
            />
          </div>
          <div className={!isMobile ? "box-data" : "box-data-mobile"}>
            {props.precision !== null ? (
              <CountUp end={props.precision} suffix="%" />
            ) : (
              100
            )}
          </div>
          <p
            className={!isMobile ? "box-label" : "box-label-mobile"}
            style={{ ...pMobileStyles }}
          >
            {t("leaderboard.table.precision")}
          </p>
        </div>
      </Grid>
      <Grid item xs={!isMobile ? true : 12}>
        <div
          data-title={t("survey.box.reactionTime")}
          {...handlers}
          className={!isMobile ? "rounded-border" : "rounded-border-mobile"}
          style={{
            ...mobileStyles,
            borderColor: props.campaignColor,
            backgroundColor:
              props.campaignColor + process.env.REACT_APP_ALPHA_HEX_FACTOR_MIN,
          }}
        >
          <div className="text-center">
            <img
              src={reaction_time}
              className={!isMobile ? "small-icon" : "small-icon-mobile"}
              alt=""
              style={{ ...iconMobileStyles }}
            />
          </div>
          <div className={!isMobile ? "box-data" : "box-data-mobile"}>
            {props.reactionTime !== null ? (
              <CountUp end={props.reactionTime} suffix='"' />
            ) : (
              "ND"
            )}
          </div>
          <p
            className={!isMobile ? "box-label" : "box-label-mobile"}
            style={{ ...pMobileStyles }}
          >
            {t("leaderboard.table.reactionTime")}
          </p>
        </div>
      </Grid>

      <h2
        style={{
          marginTop: "20px",
          textAlign: "center",
          width: "100%",
          textDecoration: "underline",
          color: props.campaignColor,
        }}
      >
        {t(props.rankingText)}
      </h2>

      <Grid
        container
        justify="center"
        style={{ paddingBottom: "1em", flexDirection: "row" }}
      >
        {props.nextSurvey !== null ? (
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: props.campaignColor, marginTop: "1em" }}
            disableElevation
            onClick={() => {
              window.location.href = "/tests/" + props.nextSurvey + "/1";
            }}
          >
            {t("survey.button.next")}
          </Button>
        ) : (
          <Link
            to={"/profile/" + props.token}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: props.campaignColor, marginTop: "1em" }}
              disableElevation
            >
              {t("survey.button.gotoLeaderboards")}
            </Button>
          </Link>
        )}
      </Grid>
      {popover}
    </Grid>
  );
}

export default SurveyResult;
