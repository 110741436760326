import React from "react";
import { Button, Grid } from "@material-ui/core";
import "./SurveyResult.css";
import { Link } from "react-router-dom";
import bannerProfilo from "../assets/banner_profilo.svg";
import { useTranslation, withTranslation } from "react-i18next";

export function UpdateProfileResult(props) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      align="center"
      justify="center"
    >
      <Grid container justify="center">
        <p
          style={{
            textAlign: "center",
            fontSize: 23,
            fontWeight: "700",
            color: props.campaignColor,
          }}
        >
          {t("registration.thanks.title")}
        </p>
        <Grid container justify="center" className="error-container">
          <div
            className="error-bar"
            style={{
              backgroundColor:
                props.campaignColor +
                process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER,
            }}
          />
          <img alt="" className="error-img" src={bannerProfilo} />
        </Grid>
        <p className="error-description">
          {t(
            props.nextSurvey
              ? "registration.thanks.recover"
              : "registration.thanks.content"
          )}
        </p>
      </Grid>
      <Grid container justify="center">
        {props.nextSurvey !== null ? (
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: props.campaignColor, marginTop: "1em" }}
            disableElevation
            onClick={() => {
              window.location.href = "/tests/" + props.nextSurvey + "/1";
            }}
          >
            {t("survey.button.next")}
          </Button>
        ) : (
          <Link to={"/profile/" + props.token} className="noUnderline">
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: props.campaignColor,
                marginTop: 30,
              }}
              disableElevation
            >
              {t("registration.thanks.button")}
            </Button>
          </Link>
        )}
      </Grid>
    </Grid>
  );
}

export default withTranslation()(UpdateProfileResult);
