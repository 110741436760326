import React, { Component } from "react";
import "./UserProfile.css";
import axios from "axios";
import { withRouter } from "react-router";
import Loading from "./Loading";
import ErrorAlert from "./ErrorAlert";
import UserProfileRender from "./UserProfileRender";
import { withTranslation } from "react-i18next";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      json: "",
      token: "",
      nickname: "",
      avatar: "",
      color: "",
      logo: "",
      registrationToken: "",
      rulesUrl: "",
      campaignName: "",
      campaignDescription: "",
      campaignId: "",
      loading: true,
      errorMessage: "",
      errorDescription: "",
      points: null,
      correctAnswerCount: null,
      numOfCompletedLinks: null,
      totalNumOfLinks: null,
      precision: null,
      reactionTime: null,
      modalOpen: false,
    };
  }

  componentDidMount() {
    //get token
    const token = this.props.match.params.token;
    //this.state.token = token;
    this.setState({
      token: token,
    });

    //console.log(this.props.t("main.header.welcome"));

    //get json data
    axios
      .get(process.env.REACT_APP_GAMIFICATION_ENDPOINT + token)
      .then((res) => {
        const json = res.data.result;

        // Update state to trigger a re-render.
        // Clear any errors, and turn off the loading indiciator.
        this.setState({
          json,
          nickname: res.data.result.user.nickname,
          avatar: res.data.result.user.avatar,
          color: res.data.result.user.color,
          registrationToken: res.data.result.user.registration_token,
          rulesUrl: res.data.result.rules_url,
          campaignName: res.data.result.user.campaign,
          campaignDescription: res.data.result.description,
          campaignId: res.data.result.user.campaignId,
          campaignColor: res.data.result.user.campaignColor,
          logo: res.data.result.user.customer_logo,
          precision: Math.round(res.data.result.user.mean_precision),
          reactionTime: Math.round(res.data.result.user.mean_reaction_time),
          points: res.data.result.user.total_score,
          numOfCompletedLinks: res.data.result.user.num_of_completed_links,
          totalNumOfLinks: res.data.result.user.total_num_of_links,
          loading: false,
          errorMessage: "",
        });
      })
      .catch((err) => {
        // Something went wrong. Save the error in state and re-render.

        let errorMessage = this.props.t("error.general.title");
        let errorDescription = this.props.t("error.general.description");

        if (err.response !== undefined) {
          errorMessage = err.response.data.title;
          errorDescription = err.response.data.description;
        }

        this.setState({
          loading: false,
          errorMessage: errorMessage,
          errorDescription: errorDescription,
        });
      });
  }

  renderComponent() {
    if (this.state.errorMessage !== "") {
      return this.renderError();
    }

    const setModalOpen = (open) => this.setState({ modalOpen: open });

    return <UserProfileRender {...{ ...this.state, setModalOpen }} />;
  }

  renderLoading() {
    return <Loading />;
  }

  renderError() {
    return (
      <ErrorAlert
        message={this.state.errorMessage}
        description={this.state.errorDescription}
      />
    );
  }

  render() {
    return (
      <div>
        {this.state.loading ? this.renderLoading() : this.renderComponent()}
      </div>
    );
  }
}

export default withTranslation()(withRouter(UserProfile));
