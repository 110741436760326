import React, { Component } from 'react';
import {CircularProgress} from "@material-ui/core";
import './Loading.css';

class Loading extends Component {
    render() {
        return (
            <CircularProgress className="middle-screen" />
        );
    }
}

export default Loading;
