import React, { useEffect, useState } from "react";
import { makeStyles } from "@bit/mui-org.material-ui.styles";
import Table from "@bit/mui-org.material-ui.table";
import TableBody from "@bit/mui-org.material-ui.table-body";
import TableCell from "@bit/mui-org.material-ui.table-cell";
import TableHead from "@bit/mui-org.material-ui.table-head";
import TableRow from "@bit/mui-org.material-ui.table-row";
import Paper from "@bit/mui-org.material-ui.paper";
import "./Leaderboard.css";
import { useTranslation } from "react-i18next";
import { medals } from "./Leaderboard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    // minWidth: 650,
  },
  longCell: {
    //minWidth: '12.7em',
  },
  shortCell: {
    maxWidth: "10px",
  },
}));

export default function FullLeaderboard(props) {
  const classes = useStyles();

  const rowsPerPage = props.rowsPerPage || 15;

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  useEffect(() => {
    const makeRows = [];
    function createData(
      rank,
      avatar,
      color,
      nickname,
      progress,
      precision,
      reactionTime,
      points,
      isCurrentUser
    ) {
      return {
        rank,
        avatar,
        color,
        nickname,
        progress,
        precision,
        reactionTime,
        points,
        isCurrentUser,
      };
    }

    function pushToLeaderBoard(users, isCurrentUser = false) {
      let counter = 1;
      users.forEach(function (user) {
        let progress =
          user.num_of_completed_links + " / " + user.total_num_of_links;
        makeRows.push(
          createData(
            counter,
            user.avatar,
            user.color,
            user.nickname,
            progress,
            user.mean_precision,
            user.mean_reaction_time,
            user.total_score,
            isCurrentUser
          )
        );
        counter++;
      });
    }

    pushToLeaderBoard(props.leaderboard);
    setRows(makeRows);
  }, [props.leaderboard]);

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell className={(classes.shortCell, "table-header")}>
              #
            </TableCell>
            <TableCell className={(classes.longCell, "table-header")}>
              {t("leaderboard.table.nickname")}
            </TableCell>
            <TableCell className={"table-header"} align="center">
              {t("leaderboard.table.points")}
            </TableCell>
            <TableCell className={"table-header hide-mobile"} align="center">
              {t("leaderboard.table.progress")}
            </TableCell>
            <TableCell className={"table-header hide-mobile"} align="center">
              {t("leaderboard.table.precision")}
            </TableCell>
            <TableCell className={"table-header hide-mobile"} align="center">
              {t("leaderboard.table.reactionTime")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .filter(
              (_, i) =>
                !Number.isInteger(props.page) ||
                (i >= props.page * rowsPerPage &&
                  i < (props.page + 1) * rowsPerPage)
            )
            .map((row) => (
              <TableRow
                key={row.rank}
                style={{
                  backgroundColor:
                    props.user.nickname == row.nickname
                      ? props.user.color +
                        process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER
                      : "",
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.shortCell}
                >
                  {medals[parseInt(row.rank) - 1] ? (
                    <img
                      src={medals[parseInt(row.rank) - 1]}
                      alt={"pos " + row.rank}
                    />
                  ) : (
                    <span style={{ paddingLeft: "0.55rem" }}>{row.rank}</span>
                  )}
                </TableCell>
                <TableCell className={classes.longCell} align="center">
                  {row.avatar !== "" ? (
                    <img
                      src={
                        require(`../../public/avatars/${row.avatar}.png`)
                          .default
                      }
                      style={{ background: row.color }}
                      alt=""
                      className="avatar"
                    />
                  ) : (
                    ""
                  )}
                  <span className="nickname">{row.nickname}</span>
                </TableCell>
                <TableCell align="center">{row.points}</TableCell>
                <TableCell className="hide-mobile" align="center">
                  {row.progress}
                </TableCell>
                <TableCell className="hide-mobile" align="center">
                  {row.precision}%
                </TableCell>
                <TableCell className="hide-mobile" align="center">
                  {row.reactionTime}"
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
