import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import axios from "axios";

const allowedLanguages = ["it", "en"];

const defaultLng = "en";
let lng = defaultLng;

const storageLanguage = localStorage.getItem("language");
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
  lng = storageLanguage;
}

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng,
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/translations/{{lng}}.json",
      allowMultiLoading: true,
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });

function changeLang(lang) {
  if (lang && typeof lang === "string") {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  }
}

// a language can be defined through location param
changeLang(new URLSearchParams(window.location.search).get("lang"));

// also we change lang on every successful request that has a campaign_lang
axios.interceptors.response.use((response) => {
  changeLang(response?.data?.result?.campaign_lang);
  return response;
});

export default i18n;
