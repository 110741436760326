import React, { memo, useMemo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SurveyResult from "./SurveyResult";
import { useTranslation } from "react-i18next";
import FireworksNew from "./FireworksNew";

const TestResultModal = memo((props) => {
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const { t } = useTranslation();

  const fireworksBD = useMemo(
    () =>
      props.open ? (
        <FireworksNew
          bg={
            props.campaignColor /* 
            al momento la trasparency è hardcodata nel file di fireworksNew, se bisogna cambiarla va hardcodata in modo diverso
            + process.env.REACT_APP_ALPHA_HEX_FACTOR_BANNER */
          }
        />
      ) : null,
    [props.open, props.campaignColor]
  );

  return (
    <div>
      {fireworksBD}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        aria-labelledby="responsive-dialog-title"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <DialogTitle
          style={{ textAlign: "center" }}
          id="responsive-dialog-title"
        >
          {t(
            props.wasLastSurvey
              ? "test.thanks.campaignCompleted"
              : "test.thanks.testCompleted"
          )}
        </DialogTitle>
        <DialogContent>
          <SurveyResult
            points={props.points}
            precision={props.precision}
            reactionTime={props.reactionTime}
            token={props.token}
            urlToken={props.urlToken}
            campaignColor={props.campaignColor}
            nextSurvey={props.nextSurvey}
            rankingText={props.rankingText}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default TestResultModal;
