import { useTranslation } from "react-i18next";
import bannerProfilo from "../assets/banner_profilo.svg";
import ErrorAlert from "./ErrorAlert";

export default function RenderEnded(props) {
  const { t } = useTranslation();
  return (
    <ErrorAlert
      banner={bannerProfilo}
      campaignColor={props.campaignColor || ""}
      message={t("campaign.header.ended")}
      description={t("campaign.body.ended")}
      profileLink={"/profile/" + (props.registrationToken ?? props.token)}
    />
  );
}
