import React, { useRef } from "react";
import "./Countdown.css";
import reaction_time from "../assets/reaction_time.svg";
import { useTranslation } from "react-i18next";

function Countdown(props) {
  const ref = useRef(null);
  let { timeTotal, timeSpent } = props;
  let expired = false;

  let timeLast = timeTotal - timeSpent;
  if (timeSpent > timeTotal) {
    timeLast = 0;
    timeSpent = 0;
    expired = true;
  }

  const [counter, setCounter] = React.useState(timeLast);
  const [width, setWidth] = React.useState(timeSpent);

  const { t } = useTranslation();

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    counter === 0 && setCounter(t("countdown.time.end"));
    setWidth(
      expired
        ? ref.current.offsetWidth
        : (ref.current.offsetWidth * (timeTotal - counter)) / timeTotal
    );
  }, [counter, ref.current]);

  return (
    <div style={{ display: "flex" }}>
      <img src={reaction_time} className="small-icon" alt="" />
      <div id="progressBar" ref={ref}>
        <div className="bar" style={{ width: width }}>
          {counter}
        </div>
      </div>
    </div>
  );
}

export default Countdown;
